<template>
  <div id="QRCode">
    <div class="download">
      <div class="downloadImg">
        <img src="../../assets/backgroundNew1.png" />
      </div>

      <div class="downloadWrap">
        <a
          class="cont-iPhone"
          href="https://apps.apple.com/cn/app/%E6%9D%8F%E7%AE%B1/id1637017431"
          target="_blank"
        >
          <div class="downloadLeft">
            <div class="left">
              <img src="../../assets/ios.png" />
            </div>

            <div class="right">iPhone</div>
          </div>
        </a>

        <div class="downloadRight">
          <div class="left">
            <img src="../../assets/android.png" />
          </div>
          <div class="right" @click="setMaskShow">Android</div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="guidebox">
      <div class="guideWrap" v-show="maskShow">
        <div class="guideImg">
          <img src="../../assets/yindao.png" />
        </div>
        <div class="cancel" @click="setMaskShow">
          <img src="../../assets/cancel (1).png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "QRCode",
    data() {
      return {
        Android: "",
        Info: {},
        maskShow: false,
      }
    },
    created() {},
    mounted() {},

    methods: {
      setMaskShow() {
        this.maskShow = !this.maskShow
        var ua = window.navigator.userAgent.toLowerCase()
        console.log(ua)
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          // this.imgShow = !this.imgShow
          console.log("是微信")
          return true
        } else {
          var div = document.getElementById("divBox")
          var link = document.createElement("a")
          link.href = localStorage.Android
          link.click()
          div.appendChild(link)
          this.Android = localStorage.getItem("Android")
          console.log("不是")
          return false
        }
      },
    },
  }
</script>
<style>
  @media screen and (max-width: 320px) {
    #qrCodeDiv > img {
      margin: auto;
      display: block !important;
      width: 140px !important;
      height: 140px !important;
    }
  }
</style>

<style scoped lang="scss">
  #QRCode {
    width: 100%;
    height: 100vh;
  }

  .download {
    width: auto;
    min-height: 100vh;
    background: #ffffff;
    position: relative;
    overflow: hidden;

    .downloadImg {
      position: relative;
    }

    .downloadImg img {
      width: 100%;
      height: 100%;
    }

    .QRCode_box {
      position: absolute;
      top: 39%;
      // left: 29.5%;
      width: 100%;
      height: 160px;
    }

    #qrCodeDiv {
      // width: 100%;
      // position: absolute;
      // left: 0;
      // top: 0;
      width: 160px;
      height: 160px;
      margin: auto;
    }

    // .QRCode_box #qrCodeDiv {
    // 	// margin: 77.5%;
    // 	display: block;
    // 	width: 160px;
    // 	height: 160px;
    // 	object-fit: contain;
    // 	display: flex;
    // 	justify-content: center;
    // }

    @media screen and (max-width: 414px) {
      .QRCode_box {
        top: 40.5% !important;
      }
    }

    @media screen and (max-width: 390px) {
      .QRCode_box {
        top: 40% !important;
      }
    }

    @media screen and (max-width: 375px) {
      .QRCode_box {
        top: 39.5% !important;
      }
    }

    .referrer {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 22vh;
    }

    .referrer div:nth-child(1) {
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .referrer div:nth-child(2) {
      margin-top: 15px;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .downloadWrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 0;
      bottom: 8vh;

      .downloadLeft {
        width: 127px;
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        margin-left: 50px;
        background: linear-gradient(180deg, #60e0ef 0%, #00bbd1 100%);
        border-radius: 24px;

        .left {
          padding: 4px 10px 0 0;
        }

        .left img {
          width: 16px;
          height: 20px;
        }

        .right {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .downloadRight {
        width: 127px;
        height: 48px;
        line-height: 48px;
        background: #ffffff;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        margin-right: 50px;
        background: linear-gradient(180deg, #60e0ef 0%, #00bbd1 100%);
        border-radius: 24px;

        .left {
          padding: 4px 10px 0 0;
        }

        .left img {
          width: 16px;
          height: 20px;
        }

        .right {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }

  // 弹窗
  .guidebox {
    width: 100%;

    .guideWrap {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);

      .guideImg {
        background: #ffffff;
        width: 80%;
        height: 500px;
        margin: 100px auto 60px;
        z-index: 999;
        position: relative;
      }

      .guideImg img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .cancel {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
    position: relative;
    margin-top: -30px;
  }

  .cancel img {
    display: block;
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
</style>
