<template>
	<div class="shareBox">
		<div class="shareTop">
			<img src="../../assets/backshares.png" />

			<div class="top">
				<!-- <div class="rule">
					<img src="../../assets/rule.png" />
					<div class="ruleTitle">规则</div>
				</div> -->

				<!-- <div class="title_box">
					<div class="title">邀请好友赚赏金</div>
					<div class="title_Dom">每邀请一位新用户都有奖励</div>
				</div> -->

			</div>
			<div class="bottom_Box">
				<div class="bottom">
					<div class="bottomOne">邀请好友共享智能健康生活</div>
					<div class="bottomTwo">【杏箱--传递爱的信使】</div>
					<!-- <a
					  class="bottomFour"
					  :href="'kq://sharebanner'"
					  target="_blank"
					>立即邀请</a> -->
					<div class="bottomFour" @click="handleClick">立即邀请</div>
				</div>
			</div>


		</div>
		<div class="shareBottom">
			<div class="shareBottoms">
				<div class="invitation">
					<img src="../../assets/zus.png" />
					<div class="invitationBox">邀请好友列表</div>
				</div>
				<div class="phone">
					<div class="phoneBox">
						<div class="left">序号</div>
						<div class="right">注册手机号</div>
					</div>
					<div class="phoneList">
						<div class="inner" v-if="Info.length > 0">
							<div class="list" v-for="(item, index) in Info" :key="index">
								<div class="listLeft">{{index+1}}</div>
								<div class="listRight">{{item.PhoneNumber | setPhoneNum}}</div>
							</div>

						</div>
						<div class="innerUser" v-else>暂无用户信息~</div>
						<!-- <div class="inner">
							<div class="list">
								<div class="listLeft">wqeqwe</div>
								<div class="listRight">15151531962</div>
							</div>
						
						</div> -->
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	import "@/utils/flexible.js"
	export default {
		name: "share",
		data() {
			return {
				account: '',
				PerPage: 0,
				Page: 0,
				Info: [],

			}
		},
		filters: {
			setPhoneNum(t) {
				t = t.replace(t.substr(3, 4), '****')
				return t
			}
		},
		created(option) {
			this.account = this.$route.query.account
			this.PerPage = 1
			this.Page = 2
		},
		mounted() {
			this.getserMember()
		},
		computed: {
			top() {
				return -this.activeIndex * 30 + "px";
			},
		},
		methods: {
			getserMember() {
				this.$http
					.get(localStorage.getItem("baseUrl") +
						"Mart/MartAccount/GetUserMember?account=" + this.account)
					.then((res) => {
						if (res.body.Code == 200) {
							this.Info = res.body.Data

						}
					})
			},

			handleClick() {
				window.location.href = 'sharebanner'
			},
		},

	}
</script>

<style scoped lang="scss">
	.shareBox {
		width: 100%;
		min-height: 100vh;
		position: relative;
		background: #f3af6e;

		.shareTop {

			.top {

				.rule {
					margin-top: 35px;
					position: fixed;
					right: 0;
					top: 0;
				}

				.rule>img {
					width: 50px;
					height: 30px;
				}

				.title_box {
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					margin-top: 114px;

					.title {
						text-align: center;
						font-size: 40px;
						font-family: PingFang SC;
						font-weight: 800;
						color: #FBCD78;
						text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);


					}

					.title_Dom {
						text-align: center;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #FCD893;
					}
				}


			}

			.ruleTitle {
				margin-top: 36px;
				right: 0;
				top: 0;
				position: fixed;
				width: 50px;
				text-align: center;
				height: 30px;
				line-height: 30px;
				z-index: 99;
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FDE1AA;

			}

			.bottom_Box {
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				margin: 0px auto 0;

				.bottom {
					width: 90%;
					height: 211px;
					background: #FFFFFF;
					border-radius: 12px;
					margin: 89% auto 0;

					.bottomOne {
						padding: 25px;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #666666;
						text-align: center;
					}

					.bottomTwo {
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #2BB7C7;
						text-align: center;


						.price {
							font-size: 80px;
							font-family: PingFang SC;
							font-weight: 800;
							color: #FB353B;
						}

						.yuan {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 800;
							color: #FB353B;
							padding-top: 70px;
							margin-left: 15px;
						}
					}

					.bottomThree {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #FB353B;
						text-align: center;
					}

					.bottomFour {
						width: 76%;
						height: 50px;
						line-height: 50px;
						font-size: 19px;
						font-family: PingFang SC;
						font-weight: 600;
						color: #FB353B;
						text-align: center;
						margin: 35px auto 0;
						background: #fce190;
						border-radius: 24px;
					}
				}

			}

		}

		.shareBottom {
			width: 100%;
			position: absolute;
			top: 0;
			margin-top: 155%;

			.shareBottoms {
				margin: 0 auto;
				width: 90%;
				// height: 315px;
				background: #FFFFFF;
				border-radius: 12px;
				margin-top: 25px;
				padding-bottom: 40px;
			}

			.invitation {
				position: absolute;
				width: 85%;
				margin: -20px auto;
			}

			.invitation img {
				margin: 0 auto;
				display: block;
				width: 200px;
				height: 40px;
			}

			.invitationBox {
				top: 0;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FFFFFF;
				position: relative;
				text-align: center;
				margin-top: -30px;

			}

			.phone {
				padding: 40px 30px 0 30px;

				.phoneBox {
					display: flex;
					justify-content: space-between;

					.left {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #333333;
					}

					.right {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #333333;
					}
				}

				.phoneList {
					// margin-top: 20px;

					.inner {
						overflow-y: scroll;
						height: 180px;
					}

					.inner::-webkit-scrollbar {
						width: 0 !important;
					}

					.innerUser {
						font-size: 12px;
						font-family: PingFang SC;
						color: #333333;
						text-align: center;
						margin-top: 20px;
					}

					.list {
						margin-top: 15px;
						height: 40px;
						line-height: 40px;
						background: #92cecf;
						border-radius: 20px;
						display: flex;
						justify-content: space-between;
						position: relative;

						.listLeft {
							margin: 5px 0 0 8px;
							display: block;
							width: 30px;
							height: 30px;
							text-align: center;
							line-height: 30px;
							background: #FFFFFF;
							border-radius: 50%;
							position: absolute;
							z-index: 999;
						}

						.listRight {
							display: block;
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #333333;
							margin-left: 170px;
						}
					}
				}
			}
		}

		.shareTop img {
			width: 100%;
			height: auto;

		}
	}
</style>
