var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login"}},[_vm._m(0),_c('div',{staticClass:"form"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-cell-group',[_c('van-field',{attrs:{"name":"pattern","left-icon":_vm.enroll.phone,"placeholder":"输入绑定的手机号","rules":[
            {
              pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
              message: '请输入正确手机号码',
            } ]},on:{"blur":_vm.blurPhone},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),(_vm.switchover === 0)?[_c('van-field',{attrs:{"center":"","left-icon":_vm.enroll.code,"error-message":_vm.codeMessage,"clearable":"","placeholder":"请输入验证码"},on:{"blur":_vm.code},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{staticClass:"verification",attrs:{"size":"small","disabled":_vm.countFlag,"type":"primary"},on:{"click":_vm.sendMobile}},[_vm._v(_vm._s(_vm.btnMsg == null ? _vm.countNum + "s后重新发送" : _vm.btnMsg))])]},proxy:true}],null,false,1199578078),model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})]:[_c('van-field',{attrs:{"left-icon":_vm.enroll.password,"type":"password","name":"pattern1","placeholder":"请输入密码(6-16位)","error-message":_vm.errorMessage,"rules":[
              {
                pattern: /^[a-zA-Z0-9]{6,16}$/,
                message: '请输入密码(6-16位)',
              } ]},on:{"blur":_vm.blurPassWord},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]],2)],1),_c('div',{staticStyle:{"text-align":"right","margin-top":"2vh","color":"#2BB7C7"}},[_c('span',{on:{"click":_vm.goRegister}},[_vm._v("没有账号?去注册")])]),_c('div',{staticStyle:{"margin-top":"10.3vh"}},[_c('van-button',{staticClass:"submitBtn",attrs:{"block":"","type":"info","native-type":"submit"},on:{"click":_vm.submit}},[_vm._v("立即登录")])],1),_c('div',{staticClass:"cutLogin"},[_c('div',{staticClass:"cutLogin_box"},_vm._l((_vm.loginWay),function(item,index){return _c('div',{key:index,staticStyle:{"text-align":"center"},on:{"click":function($event){return _vm.changeWay(index)}}},[_c('img',{attrs:{"src":require("../../assets/codeLogin.png"),"alt":""}}),_c('p',[_vm._v(_vm._s(item))])])}),0)]),_c('div',{staticClass:"clause"},[(!_vm.isShow)?_c('van-icon',{staticClass:"icon-danxuan",attrs:{"name":_vm.enroll.choice,"size":"12"},on:{"click":_vm.changeIcon}}):_c('van-icon',{staticClass:"icon-danxuan",attrs:{"name":_vm.enroll.readIcon,"size":"12"},on:{"click":_vm.changeIcon}}),_c('p',{staticClass:"agreement"},[_vm._v(" 使用\"杏箱™\"代表同意 "),_c('span',{on:{"click":_vm.agreement}},[_vm._v("《用户协议》")]),_vm._v(" 和 "),_c('span',{on:{"click":_vm.privacy}},[_vm._v("《隐私政策》")])])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('div',{staticClass:"logo_box"},[_c('div',{staticClass:"logo_img"},[_c('img',{attrs:{"src":require("../../assets/logo.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_c('span',[_vm._v("一站式家庭健康服务")])])])])}]

export { render, staticRenderFns }