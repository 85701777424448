<template>
  <div id="QRCode">
    <div class="QR_ct">
      <div class="QRCode_box">
        <div class="referrer">
          <span>您的推荐人为：</span>
          <span>{{ Info.InviterPhone || "暂无推荐人" }}</span>
        </div>
        <div class="phone">您的手机号码为：{{ Info.Tel }}</div>
        <div id="qrCodeDiv"></div>
        <p>以上为您的专属二维码</p>
      </div>
    </div>
  </div>
</template>
<script>
  import QRCode from "qrcodejs2"
  export default {
    name: "QRCode",
    data() {
      return {
        Info: {},
      }
    },
    created() {},
    mounted() {
      this.getInfo()
    },
    methods: {
      getInfo() {
        this.$http
          .get(localStorage.getItem("baseUrl") + "Mart/MartAccount/GetInfo")
          .then((res) => {
            if (res.body.Code) {
              this.Info = res.body.Data

              this.bindQRCode()
            }
          })
      },
      // 生成二维码
      bindQRCode() {
        new QRCode("qrCodeDiv", {
          text:
            location.protocol +
            "//" +
            location.host +
            "/register?InviterCode=" +
            this.Info.Account,
          width: 200,
          height: 200,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  #QRCode {
    width: 100%;
    height: 100vh;

    .QR_ct {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .QRCode_box {
        text-align: center;
        color: #2bb7c7;
        font-size: 0.6rem;

        .referrer {
          margin-bottom: 60px;
        }
        .phone {
          font-size: 0.45rem;
          margin-bottom: 30px;
        }

        #qrCodeDiv {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .QRbox {
          width: 200px;
          height: 200px;
          border: 3px solid #cbcbcb;
        }
      }
    }
  }
</style>
