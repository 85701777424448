<template>
	<div @click="handleClick">
		<div class="page-main">
			<div class="productTop">
				<!-- <img :src="Info.ProductPic[1]" /> -->
				<img :src="Info.ProductPic?Info.ProductPic[0]:''" class="img" />
			</div>
			<div class="productMiddo">
				<div class="commodity_top">
					<div class="price">
						<div class="left">
							<div>¥</div>
							<div>{{Info.ProductPrice}}</div>
						</div>
						<div class="right">库存{{Info.Stock}}</div>
					</div>
					<div class="name">{{Info.ProductName}}</div>
					<div class="abstract">
						<div>{{Info.Describe}}
						</div>
					</div>
				</div>
				<div class="division"></div>
				<div class="listbox">
					<!-- <div class="commodity_bott" v-for="(item,index) in Info.ArgsDic" :key="index">

						<div class="top">
							<div>{{item.Key}}</div>
							<div class="box">
								<div>{{item.Value}}</div>
								<div>
									<img src="../../assets/jiantou.png" />
								</div>
							</div>
						</div>
					</div> -->

					<div class="commodity_bott">

						<div class="top">
							<div>规格</div>
							<div class="box">
								<div>规格</div>
								<div>
									<img src="../../assets/jiantou.png" />
								</div>
							</div>
						</div>
						<div class="tops">
							<div>参数</div>
							<div class="boxs">
								<div>
									<span v-for="(item,index) in  Info.ArgsDic" :key="index">
										<i v-if="index>0">、</i>{{item.Key}}
									</span>
								</div>
								<div>
									<img src="../../assets/jiantou.png" />
								</div>
							</div>
						</div>
					</div>

					<!-- 					<div class="middle">
						<div>{{item.Key}}</div>
						<div class="box">
							<div>江苏省-苏州市-相城区</div>
							<div>
								<img src="../../assets/jiantou.png" />
							</div>
						</div>
					</div>
					<div class="bottom">
						<div>选择</div>
						<div class="box">
							<div>生产日期、保质期...</div>
							<div>
								<img src="../../assets/jiantou.png" />
							</div>
						</div>
					</div> -->
				</div>

				<div class="division"></div>

				<div class="productMiddt">
					<div class="productsTop">
						<div class="left">评价({{Info.ProductCommentTotal}}+)</div>
						<div class="right">查看全部></div>
					</div>
					<div class="productsBoxs">
						<div class="productsBott" v-for="(item,index) in Info.ProductComments" :key="index"
							v-if="index<maxNum">
							<div class="productsBox">
								<div class="wrapLeft">
									<img :src="item.HeadImageUrl" />
								</div>
								<div class="wrapRight">
									<div>{{item.NickName}}</div>
									<div>{{item.CreateOnStr}}</div>
								</div>

							</div>
							<div class="content">
								<div> {{item.Remark}}</div>
								<div>
									<img src="../../assets/xianqing.png" />
								</div>

							</div>

						</div>
					</div>


				</div>
				<div class="division"></div>
				<div class="productBott">
					<div class="QRCode_box">商品详情</div>

					<div class="jfxq">
						<!-- <u-parse :html="Content"></u-parse> -->
						<img :src="Info.ProductDesPic" />
						<div class="nodata" v-if="Info.ProductDesPic==null">
							<div class="noimg">
								<img src="../../assets/nodata.png" alt="">
							</div>
							<div class="no-bt">暂无数据,敬请期待!</div>
						</div>
					</div>

				</div>
			</div>


		</div>
		<!-- 底部导航 -->
		<div class="footer-box">
			<div class="left">
				<div class="service">
					<img src="../../assets/kefu.png" alt="" />
					<div class="services">客服</div>
				</div>
				<div class="collect">
					<img src="../../assets/shoucang.png" alt="" />
					<div class="collects">收藏</div>
				</div>
				<div class="shopp">
					<img src="../../assets/gouwuche.png" alt="" />
					<div class="shopps">购物车</div>
				</div>
			</div>
			<div class="right">
				<div class="addCart">加入购物车</div>
				<div class="buyNow">立即购买</div>
			</div>
		</div>
	</div>

</template>

<script>
	import "@/utils/flexible.js"
	export default {
		name: "share",
		data() {
			return {
				ucode: "",
				pid: "1567452011757043713",
				shopId: "",
				Info: [],
				ArgsDis: [],
				maxNum: 1

			}
		},
		created() {
			console.log(this.$route.query)
			if (this.$route.query) {
				this.pid = this.$route.query.pid
				this.ucode = this.$route.query.ucode
				this.getProductInfo()
			}
		},
		mounted() {
			//this.getProductInfo()
		},

		//https://h5.kqsmsc.com/#/product?pid=1373842508101976065&ucode=07888118&r=1565282223630843904
		methods: {
			getProductInfo() {
				this.$http
					.get(localStorage.getItem("baseUrl") +
						"Distribution/Product/GetDistributionProductInfo?productId=" +
						this.pid)
					.then((res) => {
						if (res.body.Code == 200) {
							this.Info = res.body.Data
						}
					})
			},

			handleClick() {
				this.$router.push({
					path: "/login",
					query: {
						ucode: this.ucode
					},
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F7F7F7;
		position: relative;

		.productTop img {
			width: 100%;
			height: auto;

		}

		.productMiddo {
			width: 100%;
			height: 160px;
			background-color: #ffffff;


			.commodity_top {
				padding: 18px;

				.price {
					display: flex;
					justify-content: space-between;

					.left {
						display: flex;

					}

					.left div:nth-child(1) {
						padding-top: 7px;
						font-size: 12px;
						font-family: SimHei;
						font-weight: 400;
						color: #FF0000;
					}

					.left div:nth-child(2) {
						margin-left: 5px;
						font-size: 20px;
						font-family: DIN;
						font-weight: bold;
						color: #FF0000;
					}

					.right {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #999999;
						opacity: 1;
					}
				}

				.name {
					margin: 13px 0 13px 0;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
				}

				.abstract div {
					width: 85%;
					font-size: 12px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
				}
			}

			.list {
				border: 1px solid red;
				margin-top: 20px;
			}

			.listbox {
				padding: 10px 15px 20px 15px;
				/* height: 90px; */
				background-color: #ffffff;
			}

			.commodity_bott {
				width: 100%;
				margin-top: 10px;
				// padding: 10px 15px 10px 15px;



				.top {
					display: flex;
					justify-content: space-around;
					margin-top: 5px;
					height: 30px;

				}

				.top>div:nth-child(1) {
					width: 70px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					// margin-left: -15px;
				}

				.box {
					display: flex;
					width: 243px;


				}

				.box>div:nth-child(1) {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
					border-bottom: 1px solid #E0E0E0;
					width: 170px;
					height: 26px;
				}

				.box>div:nth-child(2) img {
					width: 8px;
					height: 15px;
					// margin-right: 5px;
				}

				.tops {

					display: flex;
					justify-content: space-around;
					margin-top: 10px;

				}

				.tops>div:nth-child(1) {
					width: 70px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					// margin-left: -15px;
				}

				.boxs {
					display: flex;
					width: 243px;


				}

				.boxs>div:nth-child(1) {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
					width: 218px;
					height: 20px;
				}

				.boxs>div:nth-child(2) img {
					width: 8px;
					height: 15px;
					margin-left: 15px;
				}

				.middle {
					display: flex;
					justify-content: space-around;
					margin: 30px 0 30px 0;
				}

				.middle>div:nth-child(1) {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
				}

				.box {
					display: flex;
				}

				.box>div:nth-child(1) {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
				}

				.box>div:nth-child(2) img {
					width: 8px;
					height: 15px;
				}

				.bottom {
					display: flex;
					justify-content: space-around;
				}

				.bottom>div:nth-child(1) {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
				}


				.box {
					display: flex;
					justify-content: space-between;
				}

				.box>div:nth-child(1) {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #333333;
				}

				.box>div:nth-child(2) img {
					width: 8px;
					height: 15px;
				}

			}

			.division {
				width: auto;
				height: 15px;
				background-color: #F7F7F7;
			}

			.productMiddt {
				width: auto;
				// height: 250px;
				padding: 15px;
				background-color: #ffffff;

				.productsTop {
					display: flex;
					justify-content: space-between;
				}

				.productsBoxs {
					width: 100%;
					// height: 198px;

					.productsBott {
						margin: 20px 20px 0 0;

						.productsBox {
							display: flex;
							margin-top: 10px;

							.wrapLeft img {
								width: 35px;
								height: 35px;
							}

							.wrapRight {
								margin-left: 10px;
							}

							.wrapRight div:nth-child(1) {
								font-size: 12px;
								font-family: PingFang SC;
								font-weight: 400;
								color: #333333;
							}

							.wrapRight div:nth-child(2) {
								margin-top: 5px;
								font-size: 12px;
								font-family: PingFang SC;
								font-weight: 400;
								color: #999999;
							}
						}

						.content div:nth-child(1) {
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							color: #333333;
							margin: 10px 0 10px 0;
						}

						.content div:nth-child(2) img {
							width: 80px;
							height: 80px;
						}
					}
				}



			}

			.productBott {
				width: auto;
				// padding-top: 25px;
				.QRCode_box {
					padding-top: 15px;
					height: 35px;
					width: auto;
					text-align: center;
					font-size: 16px;
					color: #333333;
					font-weight: bold;
					position: relative;
					/* margin-top: 10px; */
					// padding-top: 20px;
				}

				.QRCode_box::before {
					width: 60px;
					height: 1px;
					position: absolute;
					top: 50%;
					left: 20%;
					background: #c9c9c9;
					color: #c9c9c9;
					content: "";
				}

				.QRCode_box::after {
					content: "";
					width: 60px;
					height: 1px;
					position: absolute;
					top: 50%;
					right: 20%;
					background: #c9c9c9;
					color: #c9c9c9;
				}

				.jfxq {
					width: 100%;
					padding: 0 0 55px 0;

					img {
						width: 100%;
					}

					.nodata {
						width: 100%;
						display: flex;
						flex-direction: column;
						margin: 30px 0 30px 0;

						.noimg {
							width: 80%;
							margin: auto;
							display: flex;
							justify-content: center;

							image {
								width: 80%;
							}
						}

						.no-bt {
							text-align: center;
							font-size: 14px;
							color: #999999;
							margin-top: 30px;
						}
					}
				}
			}

		}



	}

	.footer-box {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #FFFFFF;
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: 999;
		width: 100%;
		height: 55px;
		overflow-x: auto;
		overflow-y: hidden;
		z-index: 999;


		.left {
			display: flex;
			justify-content: space-around;
			width: 200px;

			// height: 50px;
			.service {
				width: 40px;
				text-align: center;
			}

			.service img {
				width: 20px;
				height: 20px;
			}

			.services {
				margin-top: 5px;
				font-size: 10px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
			}

			.collect {
				width: 40px;
				text-align: center;
			}

			.collect>img {
				width: 20px;
				height: 20px;
			}

			.collects {
				margin-top: 5px;
				font-size: 10px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
			}

			.shopp {
				width: 40px;
				text-align: center;
			}

			.shopp img {
				width: 20px;
				height: 20px;
			}

			.shopps {
				margin-top: 5px;
				font-size: 10px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
			}

		}

		.right {
			display: flex;
			margin-left: 55%;
			position: absolute;

			.addCart {
				width: 80px;
				height: 40px;
				line-height: 40px;
				background: linear-gradient(138deg, #60E0EF 0%, #00BBD1 100%);
				border-radius: 8px 0px 0px 8px;
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 44px;
				color: #FFFFFF;
				text-align: center;
			}

			.buyNow {
				width: 80px;
				height: 40px;
				line-height: 40px;
				background: linear-gradient(138deg, #60E0EF 0%, #00BBD1 100%);
				border-radius: 0px 8px 8px 0px;
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 44px;
				color: #FFFFFF;
				text-align: center;
			}
		}
	}
</style>
