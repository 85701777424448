<template>
  <div id="login">
    <div class="logo">
      <div class="logo_box">
        <div class="logo_img">
          <img src="../../assets/logo.png" alt="" />
        </div>
        <div class="title">
          <span>一站式家庭健康服务</span>
        </div>
      </div>
    </div>
    <div class="form">
      <van-form @submit="onSubmit">
        <van-cell-group>
          <!-- 手机号 -->
          <van-field
            v-model="formData.phone"
            name="pattern"
            @blur="blurPhone"
            :left-icon="enroll.phone"
            placeholder="输入绑定的手机号"
            :rules="[
              {
                pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                message: '请输入正确手机号码',
              },
            ]"
          />

          <!-- 验证码-->
          <template v-if="switchover === 0">
            <van-field
              v-model="formData.code"
              center
              @blur="code"
              :left-icon="enroll.code"
              :error-message="codeMessage"
              clearable
              placeholder="请输入验证码"
            >
              <template #button>
                <van-button
                  class="verification"
                  size="small"
                  :disabled="countFlag"
                  type="primary"
                  @click="sendMobile"
                  >{{
                    btnMsg == null ? countNum + "s后重新发送" : btnMsg
                  }}</van-button
                >
              </template>
            </van-field>
          </template>

          <!-- 密码 -->
          <template v-else>
            <van-field
              v-model="formData.password"
              :left-icon="enroll.password"
              @blur="blurPassWord"
              type="password"
              name="pattern1"
              placeholder="请输入密码(6-16位)"
              :error-message="errorMessage"
              :rules="[
                {
                  pattern: /^[a-zA-Z0-9]{6,16}$/,
                  message: '请输入密码(6-16位)',
                },
              ]"
            />
          </template>
        </van-cell-group>
      </van-form>
      <div style="text-align: right;margin-top: 2vh;color:#2BB7C7;">
        <span @click="goRegister">没有账号?去注册</span>
      </div>
      <div style="margin-top: 10.3vh;">
        <van-button
          class="submitBtn"
          block
          type="info"
          native-type="submit"
          @click="submit"
          >立即登录</van-button
        >
      </div>
      <div class="cutLogin">
        <div class="cutLogin_box">
          <div
            style="text-align: center;"
            @click="changeWay(index)"
            v-for="(item, index) in loginWay"
            :key="index"
          >
            <img src="../../assets/codeLogin.png" alt="" />
            <p>{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="clause">
        <van-icon
          :name="enroll.choice"
          @click="changeIcon"
          class="icon-danxuan"
          size="12"
          v-if="!isShow"
        />
        <van-icon
          :name="enroll.readIcon"
          @click="changeIcon"
          class="icon-danxuan"
          size="12"
          v-else
        />
        <p class="agreement">
          使用"杏箱™"代表同意
          <span @click="agreement">《用户协议》</span>
          和
          <span @click="privacy">《隐私政策》</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "login",
    data() {
      return {
        switchover: 0, //切换密码登录还是验证码登录
        loginWay: ["验证码登录", "密码登录"],
        enroll: {
          phone: require("@/assets/phone.png"),
          code: require("@/assets/code.png"),
          password: require("@/assets/password.png"),
          choice: require("@/assets/unchecked.png"),
          readIcon: require("@/assets/pitchOn.png"),
        },
        formData: {
          phone: "",
          code: "",
          password: "",
        },
        codeMessage: "", //验证码错误提示,
        btnMsg: "发送验证码",
        countFlag: false,
        countNum: 60, // 倒计时周期
        intervalBtn: {}, //定时器4
        errorMessage: "", //密码错误提示
        isShow: true,
        type: 0,
        Info: {},
      }
    },

    methods: {
      onSubmit(values) {
        console.log("submit", values)
      },
      blurPhone(val) {
        console.log(this.formData.phone)
      },
      // 点击获取验证码
      sendMobile() {
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.formData.phone
          )
        ) {
          return
        }
        this.countDown()
      },
      // 验证码提示
      code(val) {
        this.codeMessage = this.formData.code ? "" : "请输入验证码"
      },
      // 倒计时
      countDown() {
        let data = {
          PhoneNumber: this.formData.phone,
        }
        this.$http
          .post(
            localStorage.getItem("baseUrl") + "Mart/MartAccount/SendLoginSMS",
            data
          )
          .then((res) => {
            if (res.body.Code == 200) {
              // 设置btn倒计时时显示的信息
              this.btnMsg = null
              // 更改btn状态
              this.countFlag = !this.countFlag
              // 设置倒计时
              this.intervalBtn = setInterval(() => {
                if (this.countNum <= 0) {
                  // 重置btn提示信息
                  this.btnMsg = "发送验证码"
                  // 清除定时器
                  clearInterval(this.intervalBtn)
                  // 更改btn状态
                  this.countFlag = !this.countFlag
                  // 重置倒计时状态
                  this.countNum = 60
                }
                // 倒计时
                this.countNum--
              }, 1000)
            } else {
              this.$toast(res.body.Error.Message)
            }
          })
      },
      blurPassWord() {
        this.errorMessage = this.formData.password ? "" : "请输入密码"
      },
      submit() {
        if (this.switchover === 0) {
          if (this.formData.phone == "") {
            this.$toast("请输入手机号")
            return
          }
          if (this.formData.code == "") {
            this.$toast("请输入验证码")
            return
          }
          if (this.isShow == false) {
            this.$toast("请勾选用户协议！")
            return
          }
          let data = {
            PhoneNumber: this.formData.phone,
            Code: this.formData.code,
          }
          this.$http
            .post(
              localStorage.getItem("baseUrl") +
                "Mart/MartAccount/LoginBySMSPhoneCode",
              data
            )
            .then((res) => {
              if (res.body.Code == 200) {
                this.$toast("登录成功！")
                localStorage.setItem(
                  "token",
                  "Bearer " + res.body.Data.access_token
                )
                this.getInfo()
                // this.$router.push({
                // 	name: 'QRCode',

                // })
              } else {
                this.$toast(res.body.Error.Message)
              }
            })
        } else {
          if (this.formData.phone == "") {
            this.$toast("请输入手机号")
            return
          }
          if (this.formData.password == "") {
            this.$toast("请输入密码")
            return
          }
          if (this.isShow == false) {
            this.$toast("请勾选用户协议！")
            return
          }
          let data = {
            Identity: this.formData.phone,
            Password: this.formData.password,
          }
          this.$http
            .post(
              localStorage.getItem("baseUrl") + "Mart/MartAccount/Login",
              data
            )
            .then((res) => {
              if (res.body.Code == 200) {
                this.$toast("登录成功！")
                localStorage.setItem(
                  "token",
                  "Bearer " + res.body.Data.access_token
                )
                this.getInfo()
              } else {
                this.$toast(res.body.Error.Message)
              }
            })
        }
      },
      getInfo() {
        this.$http
          .get(localStorage.getItem("baseUrl") + "Mart/MartAccount/GetInfo")
          .then((res) => {
            if (res.body.Code) {
              this.Info = res.body.Data
              if (this.Info.InviterPhone == null) {
                this.getReferrer()
              }
              setTimeout(() => {
                // 页面跳转
                this.$router.push({
                  path: "/download",
                })
              }, 500)
            }
          })
      },
      getReferrer() {
        // 从APP传过来的用户信息保存在InviterCode
        let InviterCode = localStorage.getItem("InviterCode")
        this.$http
          .get(
            localStorage.getItem("baseUrl") +
              "Mart/MartAccount/SerInviter?code=" +
              InviterCode
          )
          .then((val) => {})
      },
      changeIcon() {
        this.isShow = !this.isShow
      },
      goRegister() {
        this.$router.push({
          path: "/register",
        })
      },
      // 登录切换
      changeWay(index) {
        this.switchover = index
      },
      // 用户协议
      agreement() {},
      // 隐私政策
      privacy() {},
    },
  }
</script>

<style scoped lang="scss">
  * {
    margin: 0;
  }

  #login {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/backImage1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    .logo {
      padding: 19vh 0 7vh 0;

      .logo_box {
        color: #333333;

        .logo_img {
          width: 89px;
          height: auto;
          margin: 0 auto;
          margin-bottom: 19px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .form {
      padding: 15px;

      .van-cell-group {
        border-radius: 8px;
      }

      .van-cell {
        display: flex;
        align-items: center;
        border-radius: 8px;
        height: 50px;
        // /deep/.van-field__left-icon {
        // 	margin-right: 50px !important;
        // }
      }

      .van-cell::v-deep .van-icon {
        font-size: 20px !important;
      }

      .van-cell::v-deep .van-field__left-icon {
        margin-right: 20px;
      }

      .van-cell::v-deep .van-button--small {
        height: 29px;
        padding: 0px 8px;
        font-size: 12px;
        line-height: 24px;
      }
    }

    .submitBtn {
      border-radius: 4px;
      background-color: #2bb7c7;
      border: none;
    }

    .clause {
      line-height: 12px;
      display: flex;
      /* transform: translate(-50%); */
      /* position: fixed; */
      /* bottom: 6vh; */
      /* left: 50%; */
      white-space: pre;
      align-items: center;
      justify-content: center;
      margin-top: 5vh;

      .agreement {
      }
    }

    .cutLogin {
      color: #333333;
      margin-top: 4vh;

      .cutLogin_box {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 50%;
      }
    }
  }
</style>
